import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Scroll from "react-scroll";

import aboutPic from "../assets/images/about.jpg";
import AboutParagraph from "../components/AboutParagraph";
import HomeCover from "../components/HomeCover";
import LandingLayout from "../components/LandingLayout";
import Products from "../components/Products";
import Where from "../components/Where";

const Home: React.FC = () => {
	const { t } = useTranslation();

	return (
		<LandingLayout>
			<HomeCover
				title={t("cover.title")}
				cta={{
					label: t("cover.ctaLabel"),
					onClick: () => {
						Scroll.scroller.scrollTo("about", {
							smooth: true,
							duration: 500,
							offset: -256,
						});
					},
				}}
			/>
			<AboutParagraph
				photo={aboutPic}
				title={t("about.title")}
				body={t("about.body")}
				expandLabel={t("about.expandLabel")}
				shrinkLabel={t("about.shrinkLabel")}
			/>
			<Products />
			<Where />
		</LandingLayout>
	);
};

export default Home;
