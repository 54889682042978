import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { darken, saturate } from "polished";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styled from "styled-components";

import carrotPic from "../../assets/images/carrot.png";
import onionMixedPic from "../../assets/images/onion_mixed.png";
import onionRPic from "../../assets/images/onion_r.png";
import onionWPic from "../../assets/images/onion_w.png";
import onionYPic from "../../assets/images/onion_y.png";
import onionPic from "../../assets/images/onion.png";
import potatoPic from "../../assets/images/potato.png";

const Backdrop = styled(motion.div)`
	z-index: 10;
	position: fixed;
	background: rgba(0, 0, 0, 0.25);
	will-change: opacity;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
`;

const ProductsContainer = styled.div`
	max-width: 1080px;
	margin: 0 auto;
	margin-top: 128px;
	text-align: center;

	@media (max-width: 420px) {
		margin-top: 32px;
		padding: 0 16px;
	}

	h2 {
		font-family: "Rozha One", serif;
		color: ${({ theme }) => theme.colors.text};
		font-size: 40px;
		margin: 0;
		margin-bottom: 48px;

		@media (max-width: 420px) {
			text-align: left;
		}
	}

	h3 {
		font-family: "Rozha One", serif;
		color: ${({ theme }) => theme.colors.text};
		font-size: 32px;
		margin: 0;
	}
`;

const ProductsGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 24px;

	@media (max-width: 420px) {
		grid-template-columns: 1fr;
	}
`;

const ProductDecoration = styled(motion.div)`
	display: block;
	position: absolute;
	height: 192px;
	width: 192px;
	top: 0;
	left: 32px;
	background-color: ${({ theme }) => theme.colors.secondary.main};
	z-index: -1;
	border-radius: 50%;

	@media (max-width: 420px) {
		left: 16px;
	}
`;

const Product = styled(motion.div)`
	position: relative;

	h3 {
		margin-top: 24px;
	}

	img {
		height: 256px;
		max-height: 100%;
		width: 256px;
		max-width: 100%;
		object-fit: contain;
	}
`;

const ProductModalCard = styled(motion.div)`
	position: absolute;
	display: block;
	height: 70vh;
	max-width: 1080px;
	width: calc(100% - 80px);
	background-color: ${({ theme }) => theme.colors.secondary.main};
	border-radius: 32px;
	z-index: 1;
	padding: 24px;
	box-shadow: 0 8px 32px -8px rgba(0, 0, 0, 0.25);
	pointer-events: all;

	@media (max-width: 420px) {
		height: calc(100vh - 144px);
	}
`;

const ProductModalContainer = styled(motion.div)`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 15;
	padding: 128px 0;
	display: flex;
	justify-content: center;
	pointer-events: none;

	@media (max-width: 420px) {
		padding: 48px 0;
	}
`;

const ProductModalContent = styled.div`
	position: relative;
	z-index: 2;
	pointer-events: all;
	height: 70vh;
	max-width: 1080px;
	width: calc(100% - 80px);
	padding: 24px;
	overflow-y: auto;

	@media (max-width: 420px) {
		height: calc(100vh - 144px);
	}

	h3,
	h4,
	p {
		color: ${({ theme }) => theme.colors.text};
	}

	h4 {
		margin: 0;
		margin-top: 32px;
		font-size: 24px;
	}

	p {
		font-family: "Poppins", serif;
		font-size: 18px;
		line-height: 1.4;
		max-width: 768px;
		margin: 0 auto;
		margin-top: 16px;
	}
`;

const ProductModalGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 24px;
	padding-bottom: 48px;

	@media (max-width: 1080px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 640px) {
		grid-template-columns: 1fr;
	}
`;

const ProductModalGridCell = styled.div`
	border-radius: 24px;
	padding: 16px;
	box-sizing: border-box;
	background-color: ${({ theme }) =>
		saturate(20, darken(0.1, theme.colors.secondary.main))};
	mix-blend-mode: darken;
	margin-top: 32px;
	margin-top: 144px;

	img {
		height: 256px;
		width: 256px;
		object-fit: contain;
		margin-top: -128px;
	}

	h5 {
		font-size: 24px;
		margin: 0;
		margin-top: 24px;
		text-transform: capitalize;
		color: ${({ theme }) => theme.colors.secondary.contrastColor};
	}
`;

interface Product {
	title: string;
	image: string;
	sizes: {
		label: string;
		image: string;
	}[];
	variants?: {
		label: string;
		image: string;
	}[];
}

interface ProductModalProps {
	selectedProduct: string | null;
	product: Product;
	onClose: () => void;
}

const ProductModal: React.FC<ProductModalProps> = ({
	selectedProduct,
	product,
	onClose,
}: ProductModalProps) => {
	return (
		<ProductModalContainer layoutId={selectedProduct || ""}>
			<ProductModalCard layoutId={`${selectedProduct}-card`} />
			<ProductModalContent>
				<motion.h3 layoutId={`${selectedProduct}-title`}>
					{product.title}
				</motion.h3>
				{product.variants && (
					<>
						<h4>Varianti</h4>
						<ProductModalGrid>
							{product.variants.map(({ image, label }) => (
								<ProductModalGridCell key={label}>
									<img src={image} alt="" />
									<h5>{label}</h5>
								</ProductModalGridCell>
							))}
						</ProductModalGrid>
					</>
				)}
				<h4>Formati disponibili</h4>
				<ProductModalGrid>
					{product.sizes.map(({ image, label }) => (
						<ProductModalGridCell key={label}>
							<img src={image} alt="" />
							<h5>{label}</h5>
						</ProductModalGridCell>
					))}
					<ProductModalGridCell>
						<img src={product.sizes[0].image} alt="" />
						<h5>Custom label</h5>
					</ProductModalGridCell>
				</ProductModalGrid>
			</ProductModalContent>
		</ProductModalContainer>
	);
};

const Products = () => {
	const { t } = useTranslation();
	const [selectedProduct, setSelectedProduct] = useState<string | null>(null);

	const products: Record<string, Product> = {
		potatoes: {
			title: t("products.potatoes"),
			image: potatoPic,
			sizes: [
				{
					label: "vert-bag 2Kg",
					image: potatoPic,
				},
				{
					label: "vert-bag 5Kg",
					image: potatoPic,
				},
			],
		},
		onions: {
			title: t("products.onions"),
			image: onionMixedPic,
			sizes: [
				{
					label: "vert-bag 1Kg",
					image: onionMixedPic,
				},
				{
					label: "vert-bag 5Kg",
					image: onionMixedPic,
				},
				{
					label: "tubo .5Kg",
					image: onionMixedPic,
				},
			],
			variants: [
				{
					label: "rossa",
					image: onionRPic,
				},
				{
					label: "gialla",
					image: onionYPic,
				},
				{
					label: "bianca",
					image: onionWPic,
				},
			],
		},
		carrots: {
			title: t("products.carrots"),
			image: carrotPic,
			sizes: [
				{
					label: "vassoio 0.5Kg",
					image: carrotPic,
				},
				{
					label: "vassoio 1Kg",
					image: carrotPic,
				},
				{
					label: "sacchetto 1Kg",
					image: carrotPic,
				},
				{
					label: "cassa sfusa 10Kg",
					image: carrotPic,
				},
			],
		},
	};

	return (
		<Element name="products">
			<ProductsContainer>
				<h2>{t("products.title")}</h2>
				<AnimateSharedLayout type="crossfade">
					<ProductsGrid>
						{Object.entries(products).map(([key, product]) => {
							return (
								<Product
									key={key}
									layoutId={key}
									onClick={() => setSelectedProduct(key)}
								>
									<ProductDecoration layoutId={`${key}-card`} />
									<motion.img src={product.image} alt="" />
									<motion.h3 layoutId={`${key}-title`}>
										{product.title}
									</motion.h3>
								</Product>
							);
						})}
					</ProductsGrid>

					<AnimatePresence>
						{selectedProduct && (
							<>
								<Backdrop
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0, transition: { duration: 0.15 } }}
									transition={{ duration: 0.2, delay: 0.15 }}
									style={{ pointerEvents: "auto" }}
									onClick={() => setSelectedProduct(null)}
								/>
								<ProductModal
									selectedProduct={selectedProduct}
									product={
										products[
											selectedProduct as "carrots" | "onions" | "potatoes"
										]
									}
									onClose={() => setSelectedProduct(null)}
								/>
							</>
						)}
					</AnimatePresence>
				</AnimateSharedLayout>
			</ProductsContainer>
		</Element>
	);
};

export default Products;
