import { PageProps } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import Home from "../routes/Home";

const Index: React.FC<PageProps> = () => {
	return (
		<>
			<Helmet title="Uniagro - Una mania, per la natura">
				<meta
					name="description"
					content="UNIAGRO S.r.l. nasce nel mese di gennaio del 2008 dalla Tognana s.r.l. rilevando tutte le attività nel settore della distribuzione organizzata. UNIAGRO S.r.l. nasce nel mese di gennaio del 2008 dalla Tognana s.r.l. rilevando tutte le attività nel settore della distribuzione organizzata. L’azienda è condotta dalla famiglia Tognana e questa inizia la propria attività con il commercio di prodotti ortofrutticoli nel 1926, in Piazza delle Erbe a Padova."
				/>
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://metatags.io/" />
				<meta
					property="og:title"
					content="Uniagro - Una mania, per la natura"
				/>
				<meta
					property="og:description"
					content="UNIAGRO S.r.l. nasce nel mese di gennaio del 2008 dalla Tognana s.r.l. rilevando tutte le attività nel settore della distribuzione organizzata. UNIAGRO S.r.l. nasce nel mese di gennaio del 2008 dalla Tognana s.r.l. rilevando tutte le attività nel settore della distribuzione organizzata. L’azienda è condotta dalla famiglia Tognana e questa inizia la propria attività con il commercio di prodotti ortofrutticoli nel 1926, in Piazza delle Erbe a Padova."
				/>
				<meta
					property="og:image"
					content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content="https://metatags.io/" />
				<meta
					property="twitter:title"
					content="Uniagro - Una mania, per la natura"
				/>
				<meta
					property="twitter:description"
					content="UNIAGRO S.r.l. nasce nel mese di gennaio del 2008 dalla Tognana s.r.l. rilevando tutte le attività nel settore della distribuzione organizzata. UNIAGRO S.r.l. nasce nel mese di gennaio del 2008 dalla Tognana s.r.l. rilevando tutte le attività nel settore della distribuzione organizzata. L’azienda è condotta dalla famiglia Tognana e questa inizia la propria attività con il commercio di prodotti ortofrutticoli nel 1926, in Piazza delle Erbe a Padova."
				/>
				<meta
					property="twitter:image"
					content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
				/>
			</Helmet>

			<Home />
		</>
	);
};

export default Index;
