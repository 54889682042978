import { AnimatePresence, motion } from "framer-motion";
import { withPrefix } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import gap from "../../assets/icons/gap.png";
import ifs from "../../assets/icons/ifs.png";
import qv from "../../assets/icons/qv.png";
import carrots from "../../assets/images/carrots.jpg";
import onions from "../../assets/images/onions.jpg";
import potatoes from "../../assets/images/potatoes.jpg";
import Button from "../Button";

const CoverSlide = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: calc(100vh - 80px);
	width: 100%;
	padding: 24px;
	box-sizing: border-box;

	@media (max-width: 420px) {
		align-items: flex-end;
	}
`;

const CoverContent = styled.div`
	display: grid;
	max-width: 1080px;
	margin: 0 auto;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: "title padding";
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;

	@media (max-width: 420px) {
		height: 100%;
		grid-template-columns: 1fr;
		grid-template-rows: 2fr 1fr;
		grid-template-areas:
			"padding"
			"title";
	}

	hgroup {
		grid-area: title;
	}

	h1 {
		color: ${({ theme }) => theme.colors.text};
		font-family: "Rozha One", serif;
		font-size: 40px;
		line-height: 1.4;
		margin: 0;
		margin-bottom: 24px;

		@media (max-width: 420px) {
			font-size: 28px;
		}
	}

	h3 {
		color: ${({ theme }) => theme.colors.text};
		font-family: "Rozha One", serif;
		line-height: 1.4;
		margin: 0;
		margin-bottom: 24px;

		@media (max-width: 420px) {
			font-size: 28px;
		}
	}
`;

const CoverPicture = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	height: 100vh;
	width: 60%;
	z-index: -1;

	@media (max-width: 420px) {
		height: 65vh;
		width: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
		z-index: -1;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: -1%;
		left: -1%;
		background-image: url(${withPrefix("/images/utils/coverMaskLg.svg")});
		background-size: cover;
		height: 102%;
		width: 102%;
		z-index: 1;
	}
`;

const CoverLogos = styled.div`
	position: absolute;
	left: 0;
	bottom: 24px;

	@media (max-width: 420px) {
		display: none;
	}

	& > img {
		height: 48px;
		margin-right: 12px;
		opacity: 1;
	}
`;

interface HomeCoverProps {
	title: string;
	cta: {
		label: string;
		onClick: () => void;
	};
}

const HomeCover: React.FC<HomeCoverProps> = ({
	title,
	cta,
}: HomeCoverProps) => {
	const slides = [potatoes, onions, carrots];

	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		const slideshowInterval = window.setInterval(() => {
			setCurrentSlide(currentSlide === 2 ? 0 : currentSlide + 1);
		}, 10000);

		return () => {
			window.clearInterval(slideshowInterval);
		};
	}, [slides]);

	const imageVars = {
		enter: {
			opacity: 0,
		},
		center: {
			opacity: 1,
			zIndex: 1,
		},
		exit: {
			opacity: 0,
			zIndex: 1,
		},
	};

	return (
		<CoverSlide>
			<CoverContent>
				<hgroup>
					<h1>{title}</h1>
					<Button color="secondary" onClick={cta.onClick}>
						{cta.label}
					</Button>
				</hgroup>
				<CoverLogos>
					<h3>Le nostre certificazioni</h3>
					<img src={ifs} alt="IFS food" />
					<img src={qv} alt="QV Veneto" />
					<img src={gap} alt="Global Gap" />
				</CoverLogos>
			</CoverContent>
			<CoverPicture>
				<AnimatePresence>
					<motion.img
						key={slides[currentSlide]}
						src={slides[currentSlide]}
						initial="enter"
						animate="center"
						exit="exit"
						variants={imageVars}
						transition={{
							opacity: { duration: 1 },
						}}
						alt=""
					/>
				</AnimatePresence>
			</CoverPicture>
		</CoverSlide>
	);
};

export default HomeCover;
