import { motion } from "framer-motion";
import { transparentize } from "polished";
import React from "react";
import { MapPin } from "react-feather";
import { useTranslation } from "react-i18next";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";

const pulse = (props: { theme: any }) => keyframes`
    from {
        box-shadow: 0 0 0 0px ${transparentize(
					0.8,
					props.theme.colors.primary.main,
				)},
        0 0 0 40px ${transparentize(0.8, props.theme.colors.primary.main)},
        0 0 0 80px ${transparentize(0.8, props.theme.colors.primary.main)};
    }
    
    to {
        box-shadow: 0 0 0 40px ${transparentize(
					0.8,
					props.theme.colors.primary.main,
				)},
        0 0 0 80px ${transparentize(0.8, props.theme.colors.primary.main)},
        0 0 0 120px ${transparentize(1, props.theme.colors.primary.main)};
    }
`;

const WhereContainer = styled.div`
	max-width: 1080px;
	margin: 0 auto;
	margin-top: 128px;

	@media (max-width: 420px) {
		padding: 0 16px;
	}

	h2 {
		font-family: "Rozha One", serif;
		color: ${({ theme }) => theme.colors.text};
		font-size: 40px;
		margin: 0;
		margin-bottom: 48px;
		text-align: center;
	}
`;

const WhereMap = styled.div`
	position: relative;
	height: 320px;
	border-radius: 32px;
	overflow: hidden;
	box-shadow: 0 8px 32px -16px rgba(0, 0, 0, 0.25);
`;

const PositionMarker = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colors.primary.main};
	border: 4px solid ${({ theme }) => theme.colors.white};
	box-shadow: rgba(204, 167, 81, 0.2) 0px 0px 0px 8px,
		rgba(204, 167, 81, 0.2) 0px 0px 0px 24px,
		rgba(204, 167, 81, 0.2) 0px 0px 0px 56px;
	animation: 2s linear 0s infinite normal none running ${pulse};
`;

const LocationDetails = styled(motion.div)`
	overflow: hidden;
	position: absolute;
	bottom: 24px;
	right: 24px;
	max-width: 320px;
	width: calc(100% - 24px);
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 24px;
	display: grid;
	grid-template-columns: 40px 1fr;
	grid-column-gap: 20px;
	align-items: center;
	padding: 12px 20px;
	box-sizing: border-box;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.text};
	z-index: 3;

	@media (max-width: 420px) {
		bottom: 12px;
		right: 12px;
	}

	svg {
		height: 40px;
		width: 40px;
	}

	h6,
	p {
		margin: 0;
		font-size: 14px;
		font-family: "Poppins", serif;
		font-weight: 500;
		line-height: 1.5;
	}

	h6 {
		font-size: 18px;
		font-weight: 700;
	}

	& > div {
		position: relative;
	}
`;

const Map = ReactMapboxGl({
	accessToken:
		"pk.eyJ1IjoiZWwxZmxlbSIsImEiOiJja2VlNTQybHAwY3l4MnhuNzRxaGNzcDB2In0.8-dzBo57W5mC4pLSo13k-w",
});

const Where = () => {
	const { t } = useTranslation();

	return (
		<Element name="where">
			<WhereContainer>
				<h2>{t("where.title")}</h2>
				<WhereMap>
					<Map
						center={[12.242761, 45.079155]}
						zoom={[13]}
						// eslint-disable-next-line react/style-prop-object
						style="mapbox://styles/mapbox/streets-v9"
						containerStyle={{
							height: "100%",
							width: "100%",
						}}
					>
						<Marker coordinates={[12.242761, 45.079155]} anchor="bottom">
							<PositionMarker />
						</Marker>
					</Map>
					<a
						href="https://goo.gl/maps/UbzDHTNdNh7dxLse6"
						target="_blank"
						rel="noreferrer"
					>
						<LocationDetails initial="idle" whileHover="hover" animate="idle">
							<MapPin />
							<div>
								<motion.div
									variants={{
										idle: {
											height: "100%",
											y: "0%",
										},
										hover: {
											y: "-125%",
										},
									}}
								>
									<h6>{t("where.address.name")}</h6>
									<p>{t("where.address.addressLine1")}</p>
									<p>{t("where.address.addressLine2")}</p>
								</motion.div>
								<motion.div
									variants={{
										idle: {
											display: "flex",
											position: "absolute",
											width: "100%",
											height: "100%",
											y: "125%",
											top: 0,
											right: 0,
											alignItems: "center",
										},
										hover: {
											y: "0%",
										},
									}}
								>
									<h6>Ottieni indicazioni</h6>
								</motion.div>
							</div>
						</LocationDetails>
					</a>
				</WhereMap>
			</WhereContainer>
		</Element>
	);
};

export default Where;
