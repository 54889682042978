import React, { useState } from "react";
import { Element } from "react-scroll";
import styled from "styled-components";

import Button from "../Button";

interface AboutContainerProps {
	$isCompact: boolean;
}

const AboutContainer = styled.div<AboutContainerProps>`
	max-width: 1080px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 5fr 1fr 6fr;
	grid-template-areas: "image gap content";
	margin-top: 128px;
	align-items: ${({ $isCompact }) => ($isCompact ? "center" : "flex-start")};

	@media (max-width: 420px) {
		margin-top: 32px;
		grid-template-columns: 1fr;
		grid-template-areas:
			"image"
			"content";
		padding: 24px;
		grid-gap: 24px;
	}
`;

const AboutPictureContainer = styled.div`
	position: relative;
	grid-area: image;
	width: 100%;

	&::before {
		content: "";
		display: block;
		width: 100%;
		padding-top: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
`;

const AboutText = styled.div`
	grid-area: content;

	h2 {
		font-family: "Rozha One", serif;
		color: ${({ theme }) => theme.colors.text};
		font-size: 40px;
		margin: 0;
		margin-bottom: 24px;
	}

	p {
		font-family: "Poppins", serif;
		color: ${({ theme }) => theme.colors.text};
		font-size: 18px;
		line-height: 1.4;
		margin-bottom: 8px;
		margin-top: 0;

		&:first-of-type {
			margin-top: 0;
		}
	}

	button {
		margin-top: 24px;
	}
`;

interface AboutParagraphProps {
	photo: string;
	title: string;
	body: string;
	expandLabel: string;
	shrinkLabel: string;
}

const AboutParagraph: React.FC<AboutParagraphProps> = ({
	photo,
	title,
	body,
	expandLabel,
	shrinkLabel,
}: AboutParagraphProps) => {
	const [isCompact, setIsCompact] = useState(true);

	return (
		<Element name="about">
			<AboutContainer $isCompact={isCompact}>
				<AboutPictureContainer>
					<img src={photo} alt="" />
				</AboutPictureContainer>
				<AboutText>
					<h2>{title}</h2>
					{body
						.split("\n")
						.slice(0, isCompact ? 2 : undefined)
						.map((paragraph) => (
							<p key={paragraph.slice(0, 5)}>{paragraph}</p>
						))}
					<Button color="secondary" onClick={() => setIsCompact(!isCompact)}>
						{isCompact ? expandLabel : shrinkLabel}
					</Button>
				</AboutText>
			</AboutContainer>
		</Element>
	);
};

export default AboutParagraph;
